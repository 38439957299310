// cm-scss
@import "common/var.scss";
// index-scss
@import "index/icon.scss";
// banner
.banner {
  position: relative;
  background-color: silver;
}

.banner-swiper {
  height: 630px;
  @media (max-width: $screen-xs-max) {
    height: 220px;
  }
}

.banner-btm {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 30%);
  z-index: 999;
  @media (max-width: $screen-xs-max) {
    img {
      width: 320px;
    }
  }
}

.banner-item {
  text-align: center;
  .txt {
    display: inline-block;
    color: white;
    .t {
      margin-top: 35px;
      margin-bottom: 20px;
      font-size: 32px;
      font-weight: normal;
      @media (max-width: $screen-xs-max) {
        margin-top: 18px;
        margin-bottom: 12px;
        font-size: 18px;
      }
    }
    .p {
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 15px;
      @media (max-width: $screen-xs-max) {
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
    .bn {
      @media (max-width: $screen-xs-max) {
        line-height: 30px;
        height: 30px;
        font-size: 14px;
        padding: 0 6px;
      }
    }
  }
  &:nth-child(1) {}
  &:nth-child(2) {}
  &:nth-child(3) {}
}

// in-t 
.in-t-wrap {
  color: #3c454c;
  border-bottom: 1px solid #d4d4d4;
}

.in-t {
  text-align: center;
  font-size: 30px;
  @media (max-width: $screen-xs-max) {
    font-size: 20px;
  }
}

.in-t-sub {
  margin: 0;
  padding-bottom: 20px;
  text-align: center;
  position: relative;
  font-size: 20px;
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 55px;
    height: 3px;
    background-color: #0c90f5;
  }
  @media (max-width: $screen-xs-max) {
    font-size: 16px;
    padding-bottom: 12px;
  }
}

// serve
.in-serve {
  padding-bottom: 40px;
  margin-top: 120px;
  margin-bottom: 30px;
  @media (max-width: $screen-xs-max) {
    padding-bottom: 20px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .in-t-wrap {
    margin-bottom: 60px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 30px;
    }
  }
}

.in-serve-inner {
  margin-right: -25px;
  @media (max-width: $screen-xs-max) {
    margin-right: -3%;
  }
}

.in-serve-item {
  float: left;
  width: 220px;
  height: 300px;
  margin-right: 25px;
  margin-bottom: 20px;
  padding: 25px 20px;
  box-shadow: 0 0 15px rgba(#000, .2);
  text-align: center;
  @media (max-width: $screen-xs-max) {
    width: 47%;
    margin-right: 3%;
  }
  &::before {
    content: '';
    display: block;
    height: 75px;
    background-repeat: no-repeat;
    background-position: top center;
  }
  &:hover {
    background-color: #2e99d0;
    .t {
      color: white;
      border-bottom-color: white;
    }
    .p {
      color: white;
    }
    .bn {
      background-color: white;
    }
    &.jcjs {
      &::before {
        background-image: url(../img/in_advant_icons_01_w.png);
      }
    }
    &.zhyx {
      &::before {
        background-image: url(../img/in_advant_icons_02_w.png);
      }
    }
    &.ydkf {
      &::before {
        background-image: url(../img/in_advant_icons_03_w.png);
      }
    }
    &.sjui {
      &::before {
        background-image: url(../img/in_advant_icons_04_w.png);
      }
    }
    &.ywfw {
      &::before {
        background-image: url(../img/in_advant_icons_05_w.png);
      }
    }
  }
  .t {
    font-size: 18px;
    color: #484848;
    padding-bottom: 18px;
    border-bottom: 1px solid #2e99d0;
  }
  .p {
    padding-top: 15px;
    line-height: 24px;
    font-size: 14px;
    color: #777777;
  }
  .bn {
    margin: 20px 0;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #2e99d0;
  }
  &.jcjs {
    &::before {
      background-image: url(../img/in_advant_icons_01.png);
    }
  }
  &.zhyx {
    &::before {
      background-image: url(../img/in_advant_icons_02.png);
    }
  }
  &.ydkf {
    &::before {
      background-image: url(../img/in_advant_icons_03.png);
    }
  }
  &.sjui {
    &::before {
      background-image: url(../img/in_advant_icons_04.png);
    }
  }
  &.ywfw {
    &::before {
      background-image: url(../img/in_advant_icons_05.png);
    }
  }
}

// 案例
.in-case {
  padding-top: 30px;
  background-image: url(../img/case-bg.jpg);
  background-size: cover;
  @media (max-width: $screen-xs-max) {
    padding-top: 30px;
  }
  .in-t-wrap {
    margin-top: 55px;
    margin-bottom: 45px;
    color: white;
    border-bottom-color: #5f6061;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
  }
}

.in-case-swiper {}

.in-case-list {
  margin-right: -15px;
}

.in-case-item {
  margin-bottom: 18px;
  border-bottom: 1px solid #727477;
  &:hover {
    border-bottom-color: #0c90f5;
    .t {
      color: #fff;
    }
    .overlay {
      opacity: 1;
    }
  }
  .t {
    color: #727477;
  }
  .img-wrap {
    position: relative;
  }
  .overlay {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(#0c90f5, .79);
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(../img/in-case-bg.png);
    // transition: all .3s ease-in-out 0s
  }
}

.bn-group {
  margin: 50px 0;
  margin-top: 20px;
  text-align: center;
  .bn {
    height: 45px;
    line-height: 45px;
    border-radius: 5px;
    color: #9e9e9e;
    &:hover {
      background-color: #0c90f5;
      border-color: #0c90f5;
      color: white;
    }
  }
  .swiper-prev,
  .swiper-next {
    margin: 0 10px;
    padding: 0 18px;
  }
  .more {
    width: 158px;
  }
  @media (max-width: $screen-xs-max) {
    .bn {
      height: 35px;
      line-height: 35px;
    }
    .swiper-prev,
    .swiper-next {
      margin: 0 5px;
      padding: 0 12px;
    }
    .more {
      width: 120px;
    }
  }
}

// 合作伙伴
.in-parter {
  padding-top: 45px;
  @media (max-width: $screen-xs-max) {
    padding-top: 20px;
  }
  .in-t-wrap {
    margin-bottom: 60px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
  }
}

.in-parter-inner {
  position: relative;
  .swiper-btn-grey {
    margin-top: -47px;
    width: 49px;
    height: 94px;
    background-size: contain;
    opacity: 1;
  }
  .swiper-button-prev {
    left: -100px;
    background-image: url(../img/in-parter-left.png);
  }
  .swiper-button-next {
    right: -100px;
    background-image: url(../img/in-parter-right.png);
  }
}

.in-parter-swiper {}

.in-parter-item {
  margin-bottom: 15px;
  display: block;
  img {
    width: 100%;
  }
}

// advant
.in-advant {
  padding-top: 100px;
  padding-bottom: 75px;
  background-image: url(../img/advant-bg.jpg);
  background-size: cover;
  @media (max-width: $screen-xs-max) {
    padding-top: 50px;
    padding-bottom: 25px;
  }
  .in-t-wrap {
    color: white;
    margin-top: 0;
    margin-bottom: 100px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 40px;
    }
  }
}

.in-advant-inner {
  margin: 0 -100px;
  @media (max-width: $screen-xs-max) {
    margin: 0;
  }
}

.in-advant-item {
  float: left;
  width: 25%;
  text-align: center;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 30px;
    width: 100%;
  }
  .img-group {
    margin-bottom: 30px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
    img {
      margin: 0 8px;
    }
  }
  .txt-wrap {
    color: white;
    line-height: 22px;
  }
}

// about
.in-about {
  padding-top: 50px;
  padding-bottom: 90px;
  background-image: url(../img/about-bg.png);
  background-size: cover;
  @media (max-width: $screen-xs-max) {
    padding-top: 20px;
    padding-bottom: 60px;
  }
  .in-t-wrap {
    margin-bottom: 60px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
  }
}

.in-about-inner {
  .swiper-slide {
    background-size: cover;
  }
}

.in-about-img {
  margin-right: 110px;
  float: left;
  width: 590px;
  @media (max-width: $screen-xs-max) {
    margin-right: 0;
    width: 100%;
  }
  .gallery-top {
    height: 350px;
    @media (max-width: $screen-xs-max) {
      height: 300px;
    }
    .swiper-btn-grey {
      margin-top: -37px;
      width: 37px;
      height: 74px;
      background-size: contain;
      opacity: .6;
      @media (max-width: $screen-xs-max) {
        width: 30px;
        height: 60px;
      }
    }
    .swiper-button-prev {
      left: 0;
      background-image: url(../img/in-about-left.png);
    }
    .swiper-button-next {
      right: 0;
      background-image: url(../img/in-about-right.png);
    }
  }
  .gallery-thumbs {
    margin-top: 15px;
    height: 110px;
    @media (max-width: $screen-xs-max) {
      height: 90px;
    }
  }
}

.in-about-txt {
  float: left;
  width: 500px;
  @media (max-width: $screen-xs-max) {
    width: 100%;
  }
  p {
    padding-top: 45px;
    width: 450px;
    font-size: 15px;
    color: #5d5d5d;
    line-height: 1.8;
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
    a {
      color: #f85726;
    }
  }
  .img-group {
    margin-top: 35px;
    margin-bottom: 35px;
    text-align: center;
    img {
      margin: 0 15px;
    }
  }
  .contact-area {
    float: right;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 45px;
    width: 460px;
    font-size: 16px;
    line-height: 1.6;
    color: #363636;
    border: 1px dashed #aaaaaa;
    @media (max-width: $screen-xs-max) {
      padding: 10px 20px;
      width: 100%;
    }
  }
}

// in-stage
.in-stage {
  padding-top: 70px;
  padding-bottom: 30px;
  background-image: url(../img/in-stage-bg.jpg);
  background-size: cover;
  @media (max-width: $screen-xs-max) {
    padding-top: 30px;
  }
}

.in-stage-inner {
  margin: 0 -50px;
  @media (max-width: $screen-xs-max) {
    margin: 0 -5px;
  }
}

.in-stage-item {
  width: 20%;
  float: left;
  text-align: center;
  color: white;
  @media (max-width: $screen-xs-max) {
    padding: 0 5px;
  }
  &:last-child {
    .img-wrap {
      &::after {
        display: none;
      }
    }
  }
  .img-wrap {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      right: -15px;
      top: 50%;
      transform: translateY(-50%);
      width: 31px;
      height: 31px;
      background-image: url(../img/in-stage-arrow.png);
      background-size: cover;
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
    img {
      width: 157px;
      @media (max-width: $screen-xs-max) {
        width: 100%;
      }
    }
  }
  .txt-wrap {
    .t {
      font-size: 21px;
      font-weight: normal;
      @media (max-width: $screen-xs-max) {
        font-size: 16px;
      }
    }
    .p {
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }
  }
}

// news
.in-news {
  padding-top: 50px;
  padding-bottom: 60px;
  background-image: url(../img/news-bg.jpg);
  background-size: cover;
  @media (max-width: $screen-xs-max) {
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .in-t-wrap {
    margin-bottom: 70px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }
  }
}

.in-news-inner {
  margin-right: -22px;
  @media (max-width: $screen-xs-max) {
    margin: 0;
  }
}

.in-news-item {
  margin-right: 25px;
  margin-bottom: 22px;
  padding: 15px 12px;
  width: 585px;
  float: left;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, .15);
  @media (max-width: $screen-xs-max) {
    margin-right: 0;
    width: 100%;
  }
  a {
    color: inherit;
  }
  .img-wrap {
    width: 270px;
    height: 150px;
    margin-right: 15px;
    float: left;
    @media (max-width: $screen-xs-max) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .txt-wrap {
    width: 270px;
    float: left;
    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
  .item-hd {
    .t {
      position: relative;
      margin-bottom: 15px;
      padding-left: 20px;
      font-size: 20px;
      color: #48a7f0;
      &::before {
        position: absolute;
        left: 0;
        top: 2.5px;
        content: '';
        width: 4px;
        height: 25px;
        background-color: #48a7f0;
      }
      .more {
        float: right;
        font-size: 14px;
        color: #808080;
      }
    }
  }
  .item-bd {
    line-height: 30px;
  }
}

.in-news-list {
  margin-top: -5px;
  li {
    padding-left: 10px;
    position: relative;
    border-bottom: 1px dashed #d6d6dc;
    &:last-child {
      border-bottom: none;
    }
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      background-color: #333;
    }
  }
}

.dock {
  position: fixed;
  right: 0;
  z-index: 200;
  width: 50px;
  opacity: .7;
  -moz-opacity: .7;
  height: 296px;
  top: 50%;
}

.dock.close {
  z-index: 50
}

.dock ul.icons {
  position: absolute;
  right: 0;
  padding: 0;
  width: 50px;
  border: 1px solid #333;
  border-right: none;
  -webkit-border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
  background: #111;
  list-style: none;
  -webkit-transition: all .3s ease-in-out 0s;
  -moz-transition: all .3s ease-in-out 0s;
  -o-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
  -ms-transition: all .3s ease-in-out 0s
}

.dock.close ul.icons {
  right: -70px
}

.dock ul.icons li i {
  position: relative;
  z-index: 210;
  display: block;
  width: 50px;
  height: 50px;
  background: url(../img/dock-icons.png) no-repeat;
  cursor: pointer;
  -webkit-transition: all .3s ease-in-out 0s;
  -moz-transition: all .3s ease-in-out 0s;
  -o-transition: all .3s ease-in-out 0s;
  transition: all .3s ease-in-out 0s;
  -ms-transition: all .3s ease-in-out 0s
}

.dock ul.icons li:hover i {
  opacity: .4;
  -moz-opacity: .4
}

.dock ul.icons li.up i {
  background-position: -63px -150px
}

.dock ul.icons li.tel i {
  background-position: -62px -108px
}

.dock ul.icons li.im i {
  background-position: -63px -20px
}

.dock ul.icons li.wechat i {
  background-position: -59px -66px
}

.dock ul.icons li.down i {
  background-position: -63px -190px
}

.dock ul.icons li p {
  position: absolute;
  top: 50px;
  right: -260px;
  z-index: 201;
  display: block;
  overflow: hidden;
  padding: 15px 20px;
  width: 156px;
  height: 152px;
  font-size: 14px;
  border: 1px solid #333;
  border-right: none;
  -webkit-border-radius: 10px 0 0 10px;
  -moz-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
  background: #111;
  color: #fff;
  line-height: 30px;
  opacity: 0;
  -webkit-transition: all .4s ease-in-out .1s;
  -moz-transition: all .4s ease-in-out .1s;
  -o-transition: all .4s ease-in-out .1s;
  transition: all .4s ease-in-out .1s;
  -moz-opacity: 0;
  filter: alpha(opacity=0);
  -ms-transition: all .4s ease-in-out .1s
}

.dock ul.icons li:hover p {
  right: 50px;
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100)
}

.dock ul.icons li.tel p {}

.dock ul.icons li.im p {}

.dock ul.icons li.im p a {
  margin-top: 20px;
}

.dock ul.icons li.wechat p {}

.dock ul.icons li.wechat p img {
  width: 115px;
  border: 2px solid #fff
}

.dock a.switch {
  position: absolute;
  top: 270px;
  right: 11px;
  display: block;
  width: 26px;
  height: 26px;
  background: url(../img/dock-icons.png) no-repeat 0 -640px;
  cursor: pointer;
  -webkit-transition-delay: .15s;
  -moz-transition-delay: .15s;
  -o-transition-delay: .15s;
  transition-delay: .15s;
  -ms-transition-delay: .15s
}

.dock a.switch.off {
  background-position: -32px -640px
}

// 移动端底部导航
.ft-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 18px;
  line-height: 1;
  text-align: center;
  border-top: 1px solid #b2b2b2;
  background-color: #f8f8f8;
  a {
    color: #898b92;
    &.on {
      color: #1b90f7;
    }
  }
  i {
    font-size: 22px;
  }
  p {
    margin: 0;
    margin-top: 8px;
    font-size: 12px;
  }
}